.left-space {
    margin-left: 10%;
}

// .header-item {
//     height: 100px !important;
// }

// .navbar-brand-box-horizontal {
//     padding-top: 10px;
// }