@import "../../assets/scss/theme.scss";

.table .table-light {
    text-align: center !important;
    vertical-align: middle !important;
}

tbody {
    text-align: center !important;
    // vertical-align: middle !important;
}