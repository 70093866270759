.pac-container::after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
  background-image: none !important;
  height: 0px;
}

.linklist {
  width: 100% !important;
  text-align: left !important;
}
